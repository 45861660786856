@import './themes';

@mixin nb-overrides() {
	nb-select.size-medium button {
		padding: 0.4375rem 2.2rem 0.4375rem 1.125rem !important;

		nb-icon {
			right: 0.41rem !important;
		}
	}
}
